.entity {
  margin: 10px 0;

  &__header {
    text-transform: capitalize;
    font-weight: normal;

    & a {
      display: block;

      padding: 8px 14px;

      cursor: pointer;

      user-select: none;

      text-decoration: none;
      color: inherit;

      &:hover {
        color: #fb8225;
      }
    }
  }

  &__controller {
    font-size: inherit;

    white-space: nowrap;

    & a {
      display: block;

      padding: 8px 14px;

      text-decoration: none;
      color: inherit;
    }

    &__method {
      display: inline-block;
      width: 58px;

      text-transform: uppercase;

      &--get {
        color: #34ea40;
      }

      &--post {
        color: #2c91ff;
      }

      &--delete {
        color: #ff3535;
      }

      &--put {
        color: #dc3fff;
      }
    }

    &:hover {
      background-color: color(black a(10%));
      cursor: pointer;
    }
  }

  &__content {
    font-size: 11px;
  }
}
