.page {
  &__header {
    padding: 50px 8%;
    max-width: 800px;

    &__entity {
      display: inline-block;

      text-transform: capitalize;

      font-size: 32px;
    }

    &__version {
      display: inline-block;

      margin: 0 10px 0 0;
      padding: 3px 5px;

      font-size: 16px;

      color: white;
      background-color: lightgray;

      border-radius: 3px;

      vertical-align: super;
    }
  }
}
