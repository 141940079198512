:global(html), :global(body), :global(#app) {
  height: 100%;
  overflow: hidden;
}

:global(body) {
  margin: 0;
  padding: 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 13px;

  line-height: normal;
}

:global(.undefined) {
  /* Hightlights missing css property */
  background-color: red !important;
  margin: -2px;
  padding: 2px;
}

:global(code) {
  font-family: 'Inconsolata', monospace;
}

.root {
  margin: 0;
  background-color: #fff;
  color: #444;

  display: flex;

  height: 100%;
}

.toc {
  min-width: 350px;
  max-width: 350px;

  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  border-right: 1px solid #eee;

  position: relative;

  background: #4d585a;
  color: #fff;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 40px 0 60px;
    margin-bottom: -40px;

    fill: #fff;
    background-color: #fb8225;

    & svg {
      width: 170px;
    }
  }

  &__search {
    height: 40px;

    padding: 10px;

    box-sizing: border-box;

    & input {
      width: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(200, 200, 200, .3);

    &:hover {
      background-color: rgba(200, 200, 200, .7);
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.content {
  height: 100%;

  overflow: auto;

  flex: 1;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(200, 200, 200, .3);

    &:hover {
      background-color: rgba(200, 200, 200, .7);
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
