.endpoint {
  &__header {
    background-color: #f9fafc;
    border: solid #eee;
    border-width: 1px 0;
  }

  &__controller {
    font-size: 25px;
    margin: 40px 8% 20px;
    max-width: 900px;

    & a {
      text-decoration: none;
      color: inherit;
    }

    &__method {
      text-transform: uppercase;

      &:after {
        content: '\00a0';
      }

      &--get {
        color: #34ea40;
      }

      &--post {
        color: #2c91ff;
      }

      &--delete {
        color: #ff3535;
      }

      &--put {
        color: #dc3fff;
      }
    }
  }

  &__description {
    margin: 1.5rem 8%;
    padding: 0 15px;
    max-width: 900px;
  }

  &__definition {
    padding: 40px 8%;
    max-width: 800px;
  }

  &__parameter {
    display: flex;

    line-height: 27px;

    &_selection {
      position: relative;
      margin: 20px 0;

      &__header {
        font-size: 22px;
        line-height: 30px;

        font-weight: lighter;

        margin: 10px 0;
      }
      &__content {
        border: 1px solid #eee;
      }
      &__description {
        padding: 0 15px;
        margin: 1.5rem 0;
      }
      &__type {
        padding: 0 15px;
        line-height: 27px;
      }
    }

    &__level0 &__name {
      padding-left: 15px;
    }
    &__level1 &__name {
      padding-left: 30px;
    }
    &__level2 &__name {
      padding-left: 45px;
    }
    &__level3 &__name {
      padding-left: 60px;
    }

    &:nth-of-type(2n) {
      background-color: #f9f9f9;
    }

    &:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }

    &__name {
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 8px;
      min-width: 200px;
    }

    &__type {
      padding-right: 8px;
      color: LightSlateGray;

      &--object {
        color: #2c91ff;
      }

      &--array {
        color: #34ea40;
      }

      &--string {
        color: #008080;
      }

      &--integer, &--number {
        color: #ff3535;
      }

      &--boolean {
        color: #dc3fff;
      }

      &--null {
        color: #6a7a7c;
      }

      &--binary, &--file {
        color: #14d8d8;
      }
    }

    &__description {
      padding-right: 8px;
      flex-shrink: 1;

      color: #999;
    }

    &__spacer {
      flex-grow: 1;
    }

    &__optional {
      padding-right: 15px;
      color: #999;
    }
  }

  &__responses {
    &__status {
      &es {
        position: absolute;
        top: 0;
        right: 0;
      }
      & {
        display: inline-block;
        width: 50px;

        text-align: center;

        line-height: 30px;

        cursor: pointer;

        user-select: none;

        text-decoration: none;
        color: inherit;

        &--selected {
          box-shadow: inset 0 -3px #eee;
        }

        &:hover {
          box-shadow: inset 0 -3px blue;
        }
      }
    }
  }
}
