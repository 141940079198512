.welcome {
  padding: 40px 8%;
  max-width: 800px;

  &__header {
    display: block;
    font-size: 4.242rem;
    line-height: 4.5rem;
    margin-top: 3rem;
  }
}
